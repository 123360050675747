import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactConfirmation = () => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/kontakt-potwierdzenie/">
      <SEO title="Contact confirmation" />

      <section className="global-section-contact-confirmation">
        <h1>Thank you for sending message!</h1>
        <p>We'll contact you as soon as possible.</p>
        <Link to="/" className="btn-custom btn-custom--arrow">
          Back to home page
        </Link>
      </section>
    </Layout>
  )
}

export default ContactConfirmation
